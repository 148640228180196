
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;

	@include breakpoint(giant) {
		padding-top:rem(85px);
	}

	&.lazyLoaded {
		background-size:cover
	}

	.brandingWrapper {
		padding:rem($baseGap);
		position: relative;
		text-align:center;
		width:100%;

		@include breakpoint(large) {
			padding-top:rem(57px);
			padding-left:rem(36px);
			padding-bottom:rem(49px);
			padding-right:0;
			text-align:left;

			body:not(.index) & {
				display:flex;
				align-items:center;
				padding-bottom:0;
				width:rem(530px);
				padding-top:0;
			}
		}

		@include breakpoint(giant) {
			padding-left:rem(40px);
		}

		@include breakpoint(huge) {
			body:not(.index) & {
				padding-bottom:rem(62px);
				padding-top:rem(57px);
				padding-right:rem(69px);
				width:rem(565px);
				display:block;
			}
		}

		body.index & {
			
			@include breakpoint(large) {
				&:before {
					content:"";
					display:block;
					width:calc(100% + #{rem(36px)});
					background:#cbd0e4;
					height:1px;
					position: absolute;
					left:0;
					bottom:-1px;
				}	
				
				&:after {
					content:"";
					display:block;
					width:calc(100% + #{rem(36px)});
					background:$light;
					height:1px;
					position: absolute;
					left:0;
					bottom:0;
				}
			}	
		}

		body:not(.index) & {
			@include breakpoint(large) {
				&:before {
					content:"";
					display:block;
					width:1px;
					background:#cbd0e4;
					height:100%;
					position: absolute;
					right:0;
					bottom:0;
				}	
				
				&:after {
					content:"";
					display:block;
					width:1px;
					background:$light;
					height:100%;
					position: absolute;
					right:-1px;
					bottom:0;
				}
			}	
		}
	}

	.branding {
		position: relative;
		z-index: 1;
		display:inline-block;
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}

	.headerContainer {
		background:#eaedf8;
		margin-top:rem(68px);

		@include breakpoint(large) {
			margin-top:rem(72px);
		}

		@include breakpoint(giant) {
			margin-top:rem(52px);
		}

		.headerSwiper {
			position: relative;
			.swiper-slide {
				height:rem(250px);

				&.swiper-lazy-loaded {
					background-size:cover;
				}

				@include breakpoint(medium) {
					height:rem(350px);
				}

				@include breakpoint(large) {
					height:rem(520px);
				}

				@include breakpoint(giant) {
					height:rem(590px);
				}
			}

			.infoBar {
				width:100%;
				background:#a2abd0;
				z-index:10;
				color:$light;
				font-weight: 300;
				font-size:rem(16px);
				line-height: rem(28px);
				padding-left:rem(24px);
				padding-right:rem(21px);
				padding-top:rem(16px);
				padding-bottom:rem(16px);
				text-align:center;
				
				span {
					display:block;
				}

				@include breakpoint(large) {
					position: absolute;
					bottom:0;
					left:0;
					padding-left:0;
					padding-right:0;
					font-size:rem(14px);
				}

				@include breakpoint(giant) {
					font-size:rem(16px);
					padding-left:rem($baseGap);
					padding-right:rem($baseGap);
				}

				@include breakpoint(cut) {
					display:flex;
					justify-content: space-between;
					text-align:left;
					padding-left:rem(24px);
					padding-right:rem(21px);
					flex-direction: column;
				}
			}
		}

		.headerInfo {
			color:#414142;
			padding-top:rem(68px);
			padding-left:rem(135px);
			line-height: rem(26px);

			@include breakpoint(large, max) {
				display:none;
			}

			@include breakpoint(large) {
				padding-left:rem(126px);
				padding-top:rem(36px);
				body:not(.index) & {
					padding:rem(20px);
					width:calc(100% - #{rem(565px)});
					padding-left:rem(40px);
					padding-right:rem(40px);
					justify-content:flex-start;
					flex-wrap:wrap;
					display:flex;
					align-items: center;

					& > * {
						padding-right:rem(2 * $baseGap);
						margin-bottom:rem(15px);

						&:last-child {
							margin-bottom:0;
						}
					}
				}
			}

			@include breakpoint(giant) {
				padding-top:rem(68px);
				padding-left:rem(136px);
			}

			@include breakpoint(full) {
				body:not(.index) & {
					padding-top:rem(78px);
					justify-content:space-between;
					padding-left:rem(30px);
					padding-right:rem(30px);
					align-items: flex-start;

					& > * {
						padding-right:0;
						margin-bottom:0;
					}
				}
			}

			@include breakpoint(cut) {
				padding-left:rem(135px);

				body:not(.index) & {
					
					padding-bottom:0;
					
					padding-left:rem(53px);
					padding-right:rem(33px);
					
				}
			}

			.contactList {
				margin-top:rem(33px);

				@include breakpoint(large) {
					body:not(.index) & {
						margin-top:0;
					}
				}

				dt {
					width:rem(65px);
					padding:0;
				}
				dd {
					width:calc(100% - #{rem(65px)});

					a.email {
						border-bottom:1px solid $primary;
						color:$primary;
						text-decoration: none;
						white-space: nowrap;

						&:hover,
						&:focus,
						&:active {
							color:$primary;
							border-bottom:1px solid transparent;
						}
					}
				}
			}

			.workingTime {
				margin-top:rem(37px);

				@include breakpoint(large) {
					body:not(.index) & {
						margin-top:0;
					}
				}

				dt {
					width:rem(80px);
					padding:0;
				}
				dd {
					width:calc(100% - #{rem(80px)});
				}
			}

			span.note {
				color:#8a8b8b;
				font-size:rem(16px);
				font-style:italic;
				font-weight: 400;

				@include breakpoint(giant) {
					white-space: nowrap;
				}
			}
		}
	}

	// header sub
	.headerSub {
		display:flex;
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.section {

		body:not(.index) & {
			padding-top:rem(50px);
			padding-bottom:rem(40px);

			@include breakpoint(large) {
				padding-top:rem(79px);
				padding-bottom:rem(68px);
			}
		}

		&.section01 {
			padding-top:rem(68px);

			.teaserWrapper {
				background:#e7f1fe;
				padding-top:rem(50px);
				padding-bottom:rem(150px);
				position:relative;
				border-bottom:rem(4px) solid $secondary;

				.swiper-pagination {
					width:100%;
					text-align:center;
					margin-top:rem(20px);

					@include breakpoint(small, max) {
						// margin-top:0;
						position:relative;
						top:auto;
					}

					.swiper-pagination-bullet {
						margin-left:rem(10px);
						margin-right:rem(10px);
						background:$primary;
						@extend %animatedTransform;
						width:rem(15px);
						height:rem(15px);

						&:hover,
						&:focus,
						&:active {
							opacity:1;
						}
					}
					@include breakpoint(huge) {
						display:none;
					}
				}

				@include breakpoint(tiny) {
					padding-bottom:rem(100px);
				}

				@include breakpoint(small) {
					padding-bottom:rem(150px);
				}

				@include breakpoint(large) {
					padding-top:rem(142px);
					padding-bottom:rem(217px);
				}

				.particles-js-canvas-el {
					position: absolute;
					top:0;
					left:0;
				}

				.swiper-slide {
					box-sizing: border-box;
				}
			}

			.teaserBlock {
				background:$light;

				@include breakpoint(small) {
					height:rem(797px);
				}
				
				.imageWrapper {
					height:rem(248px);
					display:block;
					text-decoration:none;
					
					&.swiper-lazy-loaded {
						background-size:cover;
						background-position: center;
					}
				}
				.teaserTitle {
					text-align:center;
					color:#4b617e;
					font-size:rem(22px);
					line-height: rem(46px);
					font-family: $displayFont;
					font-weight: 400;
					position: relative;
					margin-top:rem(25px);
					padding-bottom:rem(17px);
					margin-bottom:rem(29px);

					@include breakpoint(tiny) {
						margin-top:rem(45px);
					}

					@include breakpoint(small) {
						margin-top:rem(25px);
					}

					@include breakpoint(large) {
						margin-top:rem(45px);
					}

					&:after {
						content:"";
						background:$border;
						width:rem(172px);
						height:1px;
						display:block;
						position: absolute;
						bottom:0;
						left:50%;
						transform:translate(-50%,0);
					}
				}
				.teaserText {
					padding-left:rem($baseGap);
					padding-right:rem($baseGap);
					padding-bottom:rem(45px);

					@include breakpoint(tiny) {
						padding-left:rem(30px);
						padding-right:rem(30px);
					}

					@include breakpoint(small) {
						padding-bottom:0;
						padding-left:rem($baseGap);
						padding-right:rem($baseGap);
					}

					@include breakpoint(medium) {
						padding-left:rem(30px);
						padding-right:rem(30px);
					}

					@include breakpoint(large) {
						padding-left:rem($baseGap);
						padding-right:rem($baseGap);
					}

					@include breakpoint(giant) {
						padding-left:rem(30px);
						padding-right:rem(30px);
					}

					@include breakpoint(huge) {
						padding-left:rem($baseGap);
						padding-right:rem($baseGap);
					}

					@include breakpoint(full) {
						padding-left:rem(30px);
						padding-right:rem(30px);
					}

					ul {
						li {
							font-size:rem(16px);
							line-height: rem(26px);
							color:$dark;
						}
					}

					.btn {
						width:100%;
						display:block;

						@include breakpoint(small) {
							width:calc(100% - #{rem(36px)});
							position: absolute;
							bottom:rem(40px);
						}

						@include breakpoint(medium) {
							width:calc(100% - #{rem(89px)});
							bottom:rem(70px);
						}

						@include breakpoint(large) {
							width:calc(100% - #{rem(36px)});
							bottom:rem(40px);
						}

						@include breakpoint(giant) {
							width:calc(100% - #{rem(89px)});
							bottom:rem(70px);
						}

						@include breakpoint(huge) {
							width:calc(100% - #{rem(36px)});
							bottom:rem(40px);
						}

						@include breakpoint(full) {
							width:calc(100% - #{rem(89px)});
							bottom:rem(70px);
						}
					}
				}
				
			}

			.btn.bestellenBtn {
				display:flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				padding-right:rem(18px);
				border-bottom:0 none;
				height:100%;
				position: relative;
				padding-left:rem(96px);
				transition:300ms background-color ease;
				width:100%;
				max-width:rem(424px);
				position: absolute;
				bottom:0;
				top:unset;
				height:auto;
				left:50%;
				transform:translate(-50%,0);
				z-index:10;
				background:$secondary;
				text-align:left;

				@include breakpoint(tiny) {
					width:rem(424px);
					padding-left:rem(116px);
					padding-right:rem(36px);
				}

				&:before {
					content:"";
					display:block;
					position: absolute;
					width:rem(60px);
					height:rem(60px);
					background-image:image-url('layout/bestellen.png');
					background-size:cover;
					background-position: center;
					left:rem(18px);

					@include breakpoint(tiny) {
						left:rem(36px);
					}
				}

				&:after {
					content:"";
					display:block;
					position: absolute;
					width:rem(60px);
					height:rem(60px);
					background-image:image-url('layout/bestellen-hover.png');
					background-size:cover;
					background-position: center;
					opacity:0;
					transition:300ms opacity ease;
					left:rem(18px);

					@include breakpoint(tiny) {
						left:rem(36px);
					}
				}

				&:hover,
				&:focus,
				&:active,
				&.active {
					background:#566dd2;

					&:after {
						opacity:1;
					}
				}

				span {
					display:block;
					&:first-of-type {
						order:2;
						font-size:rem(16px);
						line-height: rem(24px);
						margin-top:rem(3px);
					}
					&:nth-of-type(2) {
						font-size:rem(22px);
						line-height: rem(24px);
						font-family: $displayFont;
					}
				}
			}
		}

		&.section02 {
			padding-top:rem(50px);
			padding-bottom:rem(60px);

			@include breakpoint(large) {
				padding-top:rem(125px);
				padding-bottom:rem(125px);
			}

			.btn {
				display:block;
				text-align:left;
				margin-bottom:rem(33px);
				float:left;

				i {
					font-size:rem(40px);
					margin-right:rem(9px);
				}
				font-size:rem(18px);
				line-height:rem(28px);

				@include breakpoint(giant) {
					margin-top:rem(33px);
				}
			}

			.divider {
				width:1px;
				height:100%;
				display:block;
				background:rgba(#000,.13);
				margin:0 auto;
			}

			.imageWrapper {
				width:rem(281px);
				height:rem(281px);
				background-image:image-url('index/image-wrapper.png');
				background-size:cover;
				background-position: center;
				display:flex;
				justify-content:center;
				align-items:center;
				margin:0 auto rem(24px);
				border-radius: 999px;

				img {
					margin-bottom:0;
				}
			}

			.description {
				font-size:rem(16px);
				line-height:rem(26px);
				font-style:italic;
				font-weight:400;
				color:#999;
			}

			.info {
				a {
					text-decoration:none;
					border-bottom:1px solid $primary;
					display:inline-block;
					margin-top:rem(13px);

					&:hover,
					&:focus,
					&:active {
						color:#566dd2;
						border-bottom:1px solid transparent;
					}
				}
			}
		}

		// cms element
		.cmsElement {
			margin-bottom:rem(3 * $baseGap);

			body.downloads & {
				margin-bottom:rem($baseGap);
			}

			@include breakpoint(small) {
				body.downloads & {
					margin-bottom:rem(2 * $baseGap);
				}
			}

			@include breakpoint(large) {
				margin-bottom:rem(2 * $baseGap);
			}

			a[data-rel] {
				display:block;
				margin-bottom:rem(2 * $baseGap);

				body.ueberUns & {
					margin-bottom:rem(10px);
				}
			}

			span.title {
				display:block;
				margin-bottom:rem(2 * $baseGap);
			}

			&:last-of-type {
				margin-bottom:rem(2 * $baseGap);
			}
		}
	}

	// Bestellen

	.enter-cart{
		position:fixed;
		right:rem(-160px);
		top:50%;
		transform:translateY(-50%);
		background:$primary;
		transition:0.5s linear right;
		z-index:100;
		
		body.cmsBackend & {
			display:none;
		}

		a{
			color:$light;
			padding:0.825rem;
			font-size:rem(30px);
			text-decoration:none;
			display:block;
			font-family: $displayFont;

			i{
				margin-right:0.925rem;
				font-family:$iconFont;
			}
		}
		&:hover,&:focus{
			right:0;
		}

		&.pointer {
			&:before {
				content: map-get($iconMap, hand);
				font-family: $iconFont;
				position: absolute;
				left: rem(-50px);
				font-size: rem(30px);
				top: rem(13px);
				color: $primary;
				-webkit-animation: pointer 1s infinite;
				animation: pointer 1s infinite;
			}
			&:hover {
				&:before {
					visibility: hidden;
				}
			}
		}
	}

	.product-box {
		margin-bottom:rem(3 * $baseGap);

		&:last-of-type {
			margin-bottom:0;
		}

		.product-img-holder {
			margin-bottom:rem($baseGap);

			@include breakpoint(tiny) {
				margin-bottom:0;
			}

			@include breakpoint(small) {
				margin-bottom:rem($baseGap);
			}
		}

		h2 {
			@include breakpoint(tiny, max) {
				margin-bottom:rem(10px) !important;
			}
		}
	}
	form.default {
		.clear-basket {
			display:none;
		}
		.product-cart {
			display:flex;
			align-items:center;
	
			i {
				margin-right:rem(10px);
			}
		}
	
		.product-quantity {
			display:flex;
			align-items: center;
	
			label {
				padding-top:0;
	
				small {
					margin-bottom:0;
				}
			}
		}

		.products {
			margin-top:rem(2 * $baseGap);
			margin-bottom:rem(2 * $baseGap);
		}
	}
	
}

@keyframes pointer {
	0% {transform: scale(1);}
	50% {transform: scale(1.4);}
	100% {transform: scale(1);}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background:$medium;
	padding-bottom:rem(60px);
	padding-top:rem(60px);
	text-align:center;

	@include breakpoint(large) {
		text-align:left;
		padding-bottom:rem(94px);
		padding-top:rem(104px);
	}

	strong {
		color:$darker;
	}

	.contactDetails {

		margin-bottom:rem(36px);

		@include breakpoint(small) {
			display:flex;
			justify-content: center;
		}

		@include breakpoint(large) {
			display:block;
			margin-bottom:0;
		}

		@include breakpoint(full) {
			display:flex;
			justify-content: flex-start;
		}

		.divider {
			display:block;
			margin-left:rem(9px);
			margin-right:rem(9px);

			@include breakpoint(small, max) {
				display:none;
			}

			@include breakpoint(large) {
				display:none;
			}

			@include breakpoint(full) {
				display:block;
			}
		}

		.email {
			border-bottom:1px solid $primary;

			&:hover,
			&:focus,
			&:active {
				border-bottom:1px solid transparent;
				color:$primary;
			}
		}

		a {
			text-decoration: none;
		}
	}

	dl.floatList {
		dt {
			width:100%;
			padding:0;

			@include breakpoint(small) {
				width:rem(79px);
			}
		}
		dd {
			width: 100%;

			@include breakpoint(small) {
				width: calc(100% - #{rem(79px)});
			}
		}

		@include breakpoint(small) {
			text-align:left;
			width:rem(220px);
			margin:0 auto;
		}

		@include breakpoint(large) {
			width:auto;
			margin:0;
		}
	}

	.note {
		font-style: italic;
		color:#9194a0;
		font-size:rem(16px);
		line-height: rem(28px);
		margin-bottom:rem(20px);
		display:block;

		@include breakpoint(large) {
			margin-bottom:0;
		}
	}
}