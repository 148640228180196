$navHeight: calc(100vh - #{rem(47px)});

@mixin pageNavi($class: "#navigation") {
  #{$class} {
    position: fixed;
    max-width: 100%;
    right: 0;
    @content;

    body.cmsBackend & {
      position: absolute;
    }
  }
}
@mixin ul {
  ul {
    width: 100%;
    @content;
  }
}
@mixin navButtons {
  a,
  &:not(.navBack) > span,
  &.navBack,
  &.hasSub > span {
    @extend %animatedTransform;
    display: block;
    cursor: pointer;
    @content;
  }
  a,
  &.hasSub > span {
    &.active {
      @extend %buttonActive;
    }
  }
  &:hover {
    &.navBack,
    > a,
    &:not(.navBack) > span {
      @extend %buttonHover;
    }
  }
}
@mixin hasSub($class: ".hasSub") {
  &#{$class} {
    > span,
    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @content;

      &:after {
        @extend %nextIcon;
        margin-left: 0.5em;
        display: inline-block;
      }
    }
    &.active {
      > a,
      > span {
        @extend %buttonActive;
      }
    }
    @content;
  }
}
@mixin sub($class: ".sub") {
  #{$class} {
    @extend %animatedTransform;
    position: absolute;
    left: 0;
    top: 0;
    @content;

    @include breakpoint($break, max) {
      transform: translateX(100%);
    }
    @include breakpoint($break) {
      opacity: 0;
      visibility: hidden;
    }
    &.current {
      @include breakpoint($break, max) {
        transform: translateX(0%);
      }
      @include breakpoint($break) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
@mixin navBack {
  &.navBack {
    @extend %animatedTransform;
    position: relative;
    @content;

    &:before {
      @extend %prevIcon;
      margin-right: 1em;
      display: inline-block;
    }
  }
}

@include breakpoint($break, max) {
  #navigation {
    min-width: 50%;
    overflow: hidden;

    & > .row > .col {
      padding-left:0;
      padding-right:0;
    }

    @if $barPos == "top" {
      top: rem($barHeight);
      bottom: 0;
    } @else if $barPos == "bottom" {
      top: 0;
      bottom: rem($barHeight);
    }
    &.firstLevel {
      nav {
        height: $navHeight;
        overflow: hidden;
        overflow-y: auto;
      }
    }
    nav {

      ul {
        
        &.navi {
          li {
            border-bottom:1px solid $light;

            &:last-of-type {
              border-bottom:0 none;
            }

            a,
            span {
              padding-left:rem($baseGap);
              padding-right:rem($baseGap);
              padding-top:rem(20px);
              padding-bottom:rem(20px);
              border-bottom:0 none;

              &:hover,
              &:focus,
              &:active,
              &.active {
                border-bottom:0 none;
                background:$secondary;
              }
            }

            &.abnahmebesteckeUndVersandgefaesseJetztOnlineBestellen {
							padding-top:0;
							padding-bottom:0;
							background:$secondary;
							display: flex;
							align-items: center;
							a {
								display:flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: center;
								padding-right:rem(18px);
								border-bottom:0 none;
                height:100%;
                width:100%;
								position: relative;
								padding-left:rem(100px);
                transition:300ms background-color ease;
                
                span {
                  padding:0;
                  background:transparent !important;
                }

								&:before {
									content:"";
									display:block;
									position: absolute;
									width:rem(60px);
									height:rem(60px);
									background-image:image-url('layout/bestellen.png');
									background-size:cover;
									background-position: center;
									left:rem(18px);
								}

								&:after {
									content:"";
									display:block;
									position: absolute;
									width:rem(60px);
									height:rem(60px);
									background-image:image-url('layout/bestellen-hover.png');
									background-size:cover;
									background-position: center;
									left:rem(18px);
									opacity:0;
									transition:300ms opacity ease;
								}

								&:hover,
								&:focus,
								&:active,
								&.active {
									background:#566dd2;

									&:after {
										opacity:1;
									}
								}

								span {
									display:block;
									&:first-of-type {
										order:2;
										font-size:rem(16px);
										line-height: rem(24px);
										margin-top:rem(3px);
									}
									&:nth-of-type(2) {
										font-size:rem(20px);
										line-height: rem(24px);
										font-family: $displayFont;
									}
								}
							}
						}

            &.hasSub {
              a,
              span {
                border-bottom: 0 none !important;
              }
              .sub {
                height: $navHeight;
                z-index:10;
                
                &.open {
                  -webkit-overflow-scrolling: touch;
                  overflow: hidden;
                  overflow-y: auto;
                }
                &:before {
                  content: attr(data-parent-name);
                  display:block;
                }

                li {
                  &.navBack {
                    &:hover,
                    &:focus,
                    &:active {
                      background:$secondary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  html.hiddenScroll {
    overflow: hidden;
  }
}

@mixin desktopVersionFunctionality {
  #navigation {
    top: 0;
    width: 100%;

    nav {
      ul {
        &.navi {
          display: flex;
          justify-content: space-between;

          li {
            a,
            &:not(.navBack) > span,
            .sub:before,
            .navBack {
              white-space: nowrap;
            }
            &.hasSub {
              position: relative;
              body.cmsBackend & {
                &:hover {
                  > .sub {
                    opacity:1;
                    visibility: visible;
                  }
                }
              }
              .sub {
                top: 100%;
                width: auto;

                .sub {
                  left: 100%;
                  top: 0;
                }
              }
            }
            &.navBack {

              &:before {
                content: $closeIcon;
                text-align: center;
                width: 100%;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    &.hoverNavigation nav ul.navi li.navBack {
      display:none;
    }
  }
}

#quickbar {
  position: fixed;
  left: 0;
  width: 100%;

  @include breakpoint($break, max) {
    height: rem($barHeight);
  }

  @if $barPos == "top" {
    top: 0;

    @if $barHide {
      &.hide {
        transform: translateY(-100%);
      }
    }
  } @else if $barPos == "bottom" {
    bottom: 0;

    @if $barHide {
      &.hide {
        transform: translateY(100%);
      }
    }
  }

  label[for="naviToggled"] {
    display: block;
    cursor: pointer;
    width: rem($barHeight * 0.4);
    height: rem($barHeight * 0.4);
    position: relative;
    overflow: hidden;

    &:after {
      @extend %animatedTransform;
      content: "";
      position: fixed;
      left: 0;
      width: 200vw;
      height: 200vh;
      display: block;
      background-color: $dark;
      opacity: 0.9;
      z-index: -1;

      @if $barPos == "top" {
        top: rem($barHeight);
      } @else if $barPos == "bottom" {
        bottom: rem($barHeight);
      }
    }

    span {
      transition: 0.4s linear;
      text-indent: -9999px;
      width: 0;
      top: 50%;
      transform: translate(-50%, -50%);

      &,
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background: $barColor;
        height: 2px;
        left: 50%;
        transform-origin: center;
      }
      &:before,
      &:after {
        top: calc(50% + 0rem);
        width: rem($barHeight * 0.4);
        transition: top 0.3s ease-in-out, transform 0.3s 0.2s linear;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

#naviToggled {
  display: none;

  &:not(:checked) {
    ~ * #navigation,
    ~ #navigation {
      @include breakpoint($break, max) {
        transform: translateX(100%);
      }
    }
    ~ #quickbar {
      label[for="naviToggled"] {
        &:after {
          opacity: 0;
          visibility: hidden;
        }
        span {
          width: rem($barHeight * 0.4);

          &:before,
          &:after {
            transform: translate(-50%, -50%);
            transition: top 0.3s 0.2s ease-in-out, transform 0.3s linear;
          }
          &:before {
            top: calc(50% + -#{rem(($barHeight * 0.4) * 0.25)});
          }
          &:after {
            top: calc(50% + #{rem(($barHeight * 0.4) * 0.25)});
          }
        }
      }
    }
  }
}
