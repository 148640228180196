// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $primary; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	text-decoration: none;
	color: $navColor;
	
	@extend %animatedTransform;

	@include breakpoint($break) {
		border-bottom:1px solid transparent;
		font-size:rem(17px);
	}

	@include breakpoint(huge) {
		font-size:rem(18px);
	}
}
%buttonHover {
	// Link Hover
	color: $light;
	border-bottom:1px solid $light;
}
%buttonActive {
	// Link Active
	color: $light;
	border-bottom:1px solid $light;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	transform: rotateY(180deg);

	@include breakpoint($break, max) {
		font-size:rem(14px);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $mainFont;
	content: '+';
	color:$secondary;
	font-weight: 900;
	font-size:rem(12px);
	width:rem(12px);
	height:rem(12px);
	background:$light;
	border-radius:rem(12px);
	text-align:center;
	display:flex;
	justify-content: center;
	align-items: center;
	line-height: rem(12px);
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $primary; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	@extend %animatedTransform;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: rem(20px) rem($baseGap);
								background-color: darken($navBg, 6%);
								color:$light;
							}
						}
					}
					@include navBack {
						@include breakpoint($break, max) {
							padding-left:rem($baseGap);
							padding-right:rem($baseGap);
							padding-top:rem(20px);
							padding-bottom:rem(20px);
							border-bottom:1px solid $light;

							span {
								padding:0;
							}
						}
					}
				}
				li:last-of-type {
					margin-bottom: 5rem;
					@include breakpoint($break) {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		
		nav {
			ul {
				&.navi {
					li {
						padding-top:rem(31px);
						padding-bottom:rem(25px);

						&.abnahmebesteckeUndVersandgefaesseJetztOnlineBestellen {
							padding-top:0;
							padding-bottom:0;
							background:$secondary;
							display: flex;
							align-items: center;
							a {
								display:flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: center;
								padding-right:rem(18px);
								border-bottom:0 none;
								height:100%;
								position: relative;
								padding-left:rem(18px);
								transition:300ms background-color ease;

								&:hover,
								&:focus,
								&:active,
								&.active {
									background:#566dd2;

									&:after {
										opacity:1;
									}
								}

								span {
									display:block;
									&:first-of-type {
										order:2;
										font-size:rem(16px);
										line-height: rem(24px);
										margin-top:rem(3px);
										display:none;
									}
									&:nth-of-type(2) {
										font-size:rem(22px);
										line-height: rem(24px);
										font-family: $displayFont;
										
									}
								}
							}
						}
						&.datenschutzerklaerung,
						&.impressum {
							display:none;
						}
						&.hasSub {
							.sub {
								left:50%;
								transform:translate(-50%,0);

								li {
									padding-top:0;
									padding-bottom:0;
									border-bottom:1px solid $light;

									&:last-of-type {
										border-bottom:0 none;
									}

									a {
										border-bottom:0 none;
										padding-left:rem($baseGap);
										padding-right:rem($baseGap);
										padding-top:rem(20px);
										padding-bottom:rem(20px);

										&:hover,
										&:focus,
										&:active,
										&.active {
											background:$secondary;
										}
									}

									&.navBack {
										padding-top:rem(20px);
										padding-bottom:rem(20px);

										&:hover,
										&:focus,
										&:active,
										&.active {
											background:$secondary;
										}
									}
								}
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

@include breakpoint(giant) {
	#navigation {
		nav {
			ul {
				&.navi {
					li {
						&.abnahmebesteckeUndVersandgefaesseJetztOnlineBestellen {
							padding-top:0;
							padding-bottom:0;
							background:$secondary;
							display: flex;
							align-items: center;
							a {
								display:flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: center;
								padding-right:rem(36px);
								border-bottom:0 none;
								height:100%;
								position: relative;
								padding-left:rem(116px);
								transition:300ms background-color ease;

								&:before {
									content:"";
									display:block;
									position: absolute;
									width:rem(60px);
									height:rem(60px);
									background-image:image-url('layout/bestellen.png');
									background-size:cover;
									background-position: center;
									left:rem(36px);
								}

								&:after {
									content:"";
									display:block;
									position: absolute;
									width:rem(60px);
									height:rem(60px);
									background-image:image-url('layout/bestellen-hover.png');
									background-size:cover;
									background-position: center;
									left:rem(36px);
									opacity:0;
									transition:300ms opacity ease;
								}

								&:hover,
								&:focus,
								&:active,
								&.active {
									background:#566dd2;

									&:after {
										opacity:1;
									}
								}

								span {
									display:block;
									&:first-of-type {
										order:2;
										font-size:rem(16px);
										line-height: rem(24px);
										margin-top:rem(3px);
										display:block;
									}
									&:nth-of-type(2) {
										font-size:rem(22px);
										line-height: rem(24px);
										font-family: $displayFont;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {

	@include breakpoint(small) {
		display: flex;
		justify-content: center;
	}

	@include breakpoint(large) {
		display:block;
		margin-bottom:rem(10px);
	}

	@include breakpoint(giant) {
		display:flex;
		padding-right:rem(20px);
	}
	

	li {

		border-bottom:1px solid $primary;

		&:last-of-type {
			border-bottom:0 none;
		}

		@include breakpoint(small) {
			margin-right: rem(68px);
			border-bottom:0 none;
		}

		@include breakpoint(large) {
			margin-right:0;
			margin-bottom:rem(10px);
		}

		@include breakpoint(giant) {
			margin-right:rem(68px);
			margin-bottom:0;
		}

		&:last-of-type {
			margin-right:0;
		}

		a {
			color:$primary;
			display:block;			
			@extend %animatedTransform;
			font-weight: 400;
			text-decoration: none;
			padding-top:rem(20px);
			padding-bottom:rem(20px);

			@include breakpoint(small) {
				padding-top:0;
				padding-bottom:0;
				border-bottom:1px solid $primary;
			}

			@include breakpoint(large) {
				display:inline-block;
			}

			@include breakpoint(giant) {
				display:block;
			}

			&:hover,
			&:focus,
			&:active,
			&.active {
				color:#566dd2;

				@include breakpoint(small) {
					border-bottom:1px solid transparent;
				}
			}
		}
	}
}