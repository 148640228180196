// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	&.disabled {
		input, textarea, select, button, label {
			pointer-events: none;
			opacity:0.5;

			a
			{
				pointer-events: all;
			}
		}
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $dark;
		font-weight: 300;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark;
		font-weight: 300;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $dark !important;
		font-weight: 300 !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $dark;
		font-weight: 300;
	}

	fieldset {
		border: none;
		margin: 0 0 1rem 0;
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend {
		@extend .h2;
		margin-bottom:rem(10px) !important;
		padding-top:rem(30px);
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		&[for="saddr"] {
			padding-top:0;
		}

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
			margin-bottom:0;
		}

		&[for="Datenschutz"] {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
		}
	}

	small {
		line-height: 1rem;
		display: block;
		margin-bottom:2rem;
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-weight: 300;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&.invalid {
			border:1px solid red;
		}

		&:invalid {
			box-shadow:none !important;
			outline:none !important;
		}

		&:focus {
			background: $medium;
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		// float: right;
		margin: 0 rem(15px) 0.5rem 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0;
		width: 100%;
	}

	.fadeCheckbox {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 0;
		padding: 0;
		margin-bottom:rem(20px);

        @include breakpoint(large) {
	        max-width:640px;
			width: 100%;
		}
		
        label {
            width: auto;
            position: relative;
            display: inline-block;
            padding-left: 0;
            // order: 2;
			margin-bottom: 0;
			// font-size:rem(16px);

			@include breakpoint(tiny, max) {
				width:90%;
			}

            &.error {
                position: absolute;
                left: 0;
                top: 140px;

                @include breakpoint(large) {
                    top: 60px;
                }
			}
			
			
			&.floatLabel {
				// font-style: italic;
				// color:#aeaeae;
				margin-right:rem(10px);

				@include breakpoint(large) {
					margin-right:rem(28px);
				}

				a {
					@extend %animatedTransform;

					&:hover,
					&:focus,
					&:active {
						color:#aeaeae;
					}
				}
			}
        }

        label.custom-check {
            width: rem(15px);
            height: rem(15px);
            position: relative;
            display: inline-block;
            margin-right: 0;
            // order:1;
			background: $primary;
			border:1px solid $primary;
            border: 0 none;
            cursor: pointer;
            padding: 0; 
			min-height: 0;
			margin-top:rem(6px);

            &:after {
            	content: '✓';
        		display: block;
                position: absolute;
                top: 50%;
                left: calc(50% + 1px);
                transform: translate(-50%, -100%);
                opacity: 0;
				transition: 300ms all;
				color:$light;
            }
        }

        input {
            position:absolute; 
            z-index:-1; 
			visibility: hidden;
			
			&.invalid {
				& + label.custom-check {
					border:1px solid red;
				}
			}

            &:checked + label.custom-check {
                &:after {
                	transform: translate(-50%, -50%);
                    opacity: 1;
                }
            }
        }
    }
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
