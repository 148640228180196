// Use this for default Icomoon

@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: (woff, ttf, svg, eot),
    $style: normal,
);

// Example font-face for Roboto
@include fontFaceV2(
    $fontName: "Roboto", 
    $fileName: "roboto-v19-latin", 
    $weights: ("300", "400", "400|italic", "500", "900"), 
    $types: all
);