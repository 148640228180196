.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.highlight {
  color: $secondary;
}

.btn {
  @extend %animatedTransform;
  background: $primary;
  border: none;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  padding: rem(17px) rem($baseGap) rem(16px);
  text-align: center;
  text-decoration: none;
  font-family: $mainFont;
  width: 100%;
  &.bestellenBtn {
    padding-top:rem(20px);
    padding-bottom:rem(13px);
  }

  &.btnDownload {
    display:inline-flex;
    padding-top:rem(21px);
    padding-bottom:rem(19px);
    padding-left:rem(50px);
    padding-right:rem(18px);
    position: relative;
    text-align:left;

    @include breakpoint(tiny) {
      padding-left:rem(64px);
      padding-right:rem(36px);
    }
    &:before {
      @extend .icon;
      @extend .icon-pdf;
      font-size:rem(28px);
      margin-right:rem(15px);
      position:absolute;
      left:rem(18px);
      top:50%;
      transform:translate(0,-50%);

      @include breakpoint(tiny) {
        left:rem(30px);
      }
    }
  }

  &.btnVideo {
    position: relative;
    padding-left:rem(68px);
    padding-top:rem(21px);
    padding-bottom:rem(19px);
    padding-right:rem(27px);
    *[class^="icon"] {
      position: absolute;
      left:rem(16px);
      top:50%;
      transform:translate(0,-50%);
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: #566dd2;
    color: $light;
  }

  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;
  margin-top:rem(20px);

  @include breakpoint(large) {
    margin-top:0;
    height: calc(100% - #{rem($baseGap)}) !important;
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

// .notificationArea {
//   position: fixed;
//   bottom: rem(40px);
//   left: rem(40px);
//   z-index: 999999;
//   visibility: hidden;
  
//   @include breakpoint(large) {
//     left: rem(60px);
//   }

//   > :not(:last-child) {
//     margin-bottom: $baseGap;
//   }

//   .notification {
//     position: relative;
//     transition: 300ms all ease-in-out;
//     overflow: hidden;
//     padding: 1em;
//     background: $alert;
//     font-size: rem(12px);
//     line-height: rem(16px);
// 	  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
//     width: rem(300px);
//     max-width: calc(100vw - 80px);
//     transform-origin: left center;
//     max-height:9999999px;
//     visibility: visible;

//     @include breakpoint(large) {
//       font-size:rem(16px);
//       line-height: rem(22px);
//     }

//     &,
//     a {
//       color: $light;
//     }

//     &.success {
//       background: #333;
      
//       &, .close {
//         color: #fff;
//       }
//     }
//     &.error {
//       background: $alert
//     }
//     &.success, &.error {
//       p {
//         margin-bottom: rem(5px);
//       }
//       p + ul {
//         margin-top: rem(5px);
//       }
//     }

//     .close {
//       position: absolute;
//       top: 0;
//       right: 0;
//       padding-top: 5px;
//       padding-right: 8px;
//       cursor: pointer;
//       color: #fff;
//       font-size: 1.2rem;
//     }

//     &.hide {
//       opacity: 0;
//       visibility: hidden;
//       padding-top: 0;
//       padding-bottom: 0;
//       margin-left:-100px;
//     }
//   }
// }

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

.popUpContainer {

  body:not(.cmsBackend) & {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  transition: opacity .3s, visibility .3s;
  position: fixed;
  visibility: visible;
  opacity: 1;
  z-index: 1000000;
  
  .popUpContent {
  width: 100%;
  //background: $light;
  max-height: 90vh;
  max-width: calc(100% - #{rem(110px)}); // for the close label
  overflow: hidden;
  overflow-y: auto;
  
  @include breakpoint(giant) {
  max-width: 75%!important;
  }
  
  @include breakpoint(full) {
  max-width: 65%!important;
  }
  }
  }
  
  label {
  display:none;
  
  body:not(.cmsBackend) & {
  background: rgba(black, 0.8);
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding: rem($baseGap);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  
  i {
  cursor: pointer;
  display: block;
  height: rem(35px);
  width: rem(35px);
  position: relative;
  transition: background .3s;
  
  &:hover {
  background: rgba(black, 0.8);
  
  &:before, &:after {
  background: white;
  };
  }
  
  &:before, &:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: rem(30px);
  height: rem(2px);
  background: #ddd;
  }
  
  &:before {
  transform: translate(-50%,-50%) rotate(45deg);
  }
  
  &:after {
  transform: translate(-50%,-50%) rotate(-45deg);
  }
  }
  }
  }
  }
  
  input[id^="popUpToggled"] {
  display: none;
  
  &:not(:checked) {
  
  + {
  
  .popUpContainer {
  position: relative;
  opacity: 0;
  visibility: hidden;
  z-index: -1000000;
  transform:scale(0);
  pointer-events: none;
  width: 0;
  height: 0;
  }
  }
  }
  }

.noMargin {
  margin-bottom:0;
}