/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 28px;

$h1Size: 46px;

$h2Size: 26px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 20px;

$h6Size: 20px;

$mainFont: "Roboto", sans-serif;

$displayFont: "Georgia", sans-serif;

$iconFont: "icomoon", sans-serif;

// Project colors

$primary: #3e4c88;
$secondary: #ff5977;
$light: #fff;
$medium: #f1f3fa;
$tertiary: #b8cae1;
$dark: #737373;
$darker: #515151;
$border: #b8cae1;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "\e909", 
	minus: "\e90c", 
	angle-right: "\e902", 
	plus: "\e90a", 
	angle-up: "\e903", 
	exclamation: "\e90d", 
	arrow-right: "\e901", 
	play-circle: "\e907", 
	pdf: "\e905", 
	double-angle: "\f101", 
	trash: "\f1f8", 
	clipboard: "\f0ea", 
	hand: "\f0a4", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6, 12),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (6),
	),
	large: (
		width: em(992px, $bpContext), 
		bottom: (align-items:flex-end),
		cols: (1, 3, 4, 5, 6, 7, 12),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 4, 5, 6, 7, 8),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (3, 4, 5, 8),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

